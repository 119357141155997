import { Link, useParams, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Form from 'react-bootstrap/Form';

import $ from 'jquery'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import InputMask from 'react-input-mask';



export function Atas() {
    
    const $ = window.$;
    var alterClass = function() {
    var ww = document.body.clientWidth;
    if (ww < 510) { 
    } else {
    };
    };
    $(window).resize(function(){
    alterClass();
    });

    $(document).ready(function(){
        $(".owl-carousel").owlCarousel();
    })
    $(window).on('load', function() {
      $('#myModal').modal('show');
  });
   
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const location = useLocation().state;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [fone, setFone] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [show, setShow] = useState(true);
    function sendEmail(e) {
    e.preventDefault();
        
        const templateParams = {
            nome: name,
            cnpj: cnpj,
            email: email,
            telefone: fone,

            data:today.toLocaleDateString(),
        }
        
        emailjs.send('service_2d9161b','template_nu1kk5r',templateParams,'FBOz0yqrWJ-MgppvE')
        .then((response) => {
            console.log("Email Enviado",response.status, response.text);
            setName('');
            setEmail('');
            setFone('');
            setCnpj('');
            
        })
    }
    function EmailModal(send) {
        if (send){
            <>

            </>
        }
    }

    function goTo()
    {
        document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
    }

    return (
        <>
            <div class="container-fluid fixed-top topo-ata" >
                <div class="container">
                    <div class="row" style={{maxHeight:"50px"}}>
                            <div class="col-2 col-md-7 ata-col" style={{maxHeight:"50px"}}>
                                <div class="text-center logo-ata">
                                    <img  src="img/Landing/ata-logo.png"></img>
                                </div>
                            </div>
                            <div id="numero2" style={{marginTop:"32px",fontWeight:700,fontSize:"20px"}}>Fale com um especialista:</div>
                            <div style={{marginTop:"5px"}} id="numero2" class="col-6 col-md-1">
                                <button type="button" class="btn btn-light atas-fale" style={{padding:"10px 10px 10px 10px"}}>
                                    <a href="tel:+4137730162">
                                    <span id="faleconosco-atas-1" style={{color:"black",fontWeight:700,fontSize:"20px"}} >(41) 3773-0162</span>
                                    </a>
                                </button>
                            </div>
                        </div>
                </div>
                    
            </div>


            <div class="container-fluid banner-fluid banner-ata" style={{backgroundImage: 'url(../img/Landing/ata-hospitalar-banner.jpg)',}}>
                <div class="container">
                <div class="row" style={{marginLeft:"-100px"}}>
                        <div class="col" style={{paddingLeft:36,marginTop:250}}>
                            <h1  class="atas-text-1" style={{fontSize: '2.2rem', }}>
                                EQUIPAMENTOS HOSPITALARES
                            </h1>
                            <h1 class="atas-text-2" style={{fontSize: '2.7rem'}}>
                                PARA ÓRGÃOS PÚBLICOS
                            </h1>
                            <h5 class="atas-text-3"  style={{fontSize: '1.15rem'}}>
                                A Ata hospitalar é especialista no fornecimento 
                                <br></br>
                                de material hospitalar para Prefeituras do Brasil,
                                <br></br>
                                realizando negócios por Atas JÁ LIBERADAS.
                            </h5>
                            <div id="numero" class="col-8 col-md-2">
                            <button type="button" class="btn btn-light atas-fale" style={{padding:"10px 20px 10px 20px"}} onClick={goTo}>
                                <span id="faleconosco-atas-2" style={{color:"black",fontWeight:700,fontSize:"17px"}} >FALE COM NOSSOS ESPECIALISTAS</span>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                    
            </div>

            <div class="banner-ata-2">
                <div class="container-fluid banner-ata-22 " style={{backgroundImage: 'url(./img/Landing/ata-hospitalar-banner-p-2.jpg)',}}></div>
                
                <div class="container-fluid">
                <div class="row text-center"  style={{backgroundColor:"#293E41"}}>
                        <div class="col" style={{paddingLeft:36,marginTop:10,paddingBottom:36}}>
                            <h1  class="atas-text-1 text-left" style={{fontSize: '2rem', }}>
                                EQUIPAMENTOS HOSPITALARES
                            </h1>
                            <h1 class="atas-text-2 text-left" style={{fontSize: '2.5rem'}}>
                                PARA ÓRGÃOS PÚBLICOS
                            </h1> 
                            <h5 class="atas-text-3 text-left"  style={{fontSize: '1.18rem'}}>
                                A Ata hospitalar é especialista no fornecimento 
                                <br></br>
                                de material hospitalar para Prefeituras do Brasil,
                                <br></br>
                                realizando negócios por Atas JÁ LIBERADAS.
                            </h5>
                            <div id="numero" class="col-md-2  ">
                            <button type="button" class="btn btn-light atas-fale  " style={{padding:"10px 20px 10px 20px"}}  onClick={goTo}>
                                <span id="faleconosco-atas-3" style={{color:"black",fontWeight:700,fontSize:"17px"}} >FALE COM NOSSOS ESPECIALISTAS</span>
                            </button>
                        </div>
                        </div>
                </div>
                </div>
                
            </div>
           
            <div class="container-fluid" style={{backgroundColor:"#E2E9EF"}}>
                <div class=" carousel-atas">
                <OwlCarousel className='owl-theme' loop margin={50} items="" center stagePadding="0px" autoWidth autoPlay>
                    <div class='itemCar'>
                    <div class="card text-center" style={{width: "auto", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body">
                            <img src="img/cat1.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Moveis Hospitalares</div>
                            <p class="card-text"><img style={{width:"10rem",marginLeft:"50px"}} src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                    </div>
                    <div class='itemCar'>
                    <div class="card text-center" style={{width: "auto", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body">
                            <img src="img/cat2.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Camas Hospitalares</div>
                            <p class="card-text"><img style={{width:"10rem",marginLeft:"50px"}} src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                    </div>
                    <div class='itemCar'>
                    <div class="card text-center" style={{width: "auto", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body">
                            <img src="img/cat3.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Caderias de rodas</div>
                            <p class="card-text"><img style={{width:"10rem",marginLeft:"50px"}} src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                    </div>
                </OwlCarousel>

                </div>
                <div class="container cards-atas" style={{paddingTop:"82px",paddingBottom:"100px"}}>
                <div class="row">
                <div class="col">
                        <div class="card text-center" style={{width: "19rem", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body" style={{ margin:""}}>
                            <img src="img/cat1.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Moveis Hospitalares</div>
                            <p class="card-text"><img class="star" src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                        <div class="card text-center" style={{width: "19rem", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body">
                            <img src="img/cat2.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Camas Hospitalares</div>
                            <p class="card-text"><img class="star" src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                        <div class="card text-center" style={{width: "19rem", borderRadius:"20px",boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"}}>
                        <div class="card-body">
                            <img src="img/cat3.png" class="card-img-top" alt="..."></img>
                            <div class="card-title">Cadeiras de Rodas</div>
                            <p class="card-text"><img class="star" src="/img/stars.png"></img></p>
                            <a onClick={goTo} class="btn btn-atas">CONSULTE</a>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>

            <div class="container-fluid text-center">
                <div class="h3" style={{fontWeight:700,color:"black", color:"#2D737F", marginTop:"60px", marginBottom:"40px"}}>TODOS OS PRODUTOS</div>
                <OwlCarousel className='owl-theme' loop margin={0} nav dots autoplay items="4" stagePadding="" center>
                    <div class='itemCar'>
                        <img src="img/itens/CadeiraBanho2.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/cat2.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/cat3.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/itens/Escada1.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/itens/Maca1.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/itens/Maca2.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/itens/Papagaio1.png"></img>
                    </div>
                    <div class='itemCar'>
                        <img src="img/itens/Suporte1.png"></img>
                    </div>
                </OwlCarousel>
                <div style={{color:"#7F7F7F",fontSize:"30px",fontWeight:"200", marginBottom:"40px", marginTop:"20px"}}>Pegue carona em licitação e evite enfrentar longos processos burocráticos.<br></br>É possível agilizar o processo através de uma Ata e um fornecedor parceiro para a<br></br>aquisição de equipamentos hospitalares, TUDO DENTRO DAS LEIS VIGENTES.</div>
                <button type="button" class="btn btn-light atas-fale" style={{padding:"10px 20px 10px 20px",marginBottom:"50px"}}>
                                <a onClick={goTo}>
                                <span id="faleconosco-atas-4" style={{color:"black",fontWeight:700,fontSize:"17px"}} >FALE COM NOSSOS ESPECIALISTAS</span>
                                </a>
                </button>
            </div>
            <div class="container-fluid banner-fluid equip-med" style={{backgroundImage: 'url(../img/Landing/equipamentos-medicos.jpg)',}}></div>
            <div class="equip-med-2">
                <div class="container-fluid banner-fluid" style={{backgroundImage: 'url(../img/Landing/equipamentos-medicos-p.jpg)',}}></div>
            </div>
            <div class="container-fluid texto-da-ata-1">
                <div class="h3" style={{fontWeight:700,color:"black",paddingTop:"80px"}}>ESPECIALISTAS EM ARP - ATAS DE REGISTRO DE PREÇOS</div>
                <div class="p" style={{color:"black",fontSize:20, paddingBottom:"100px", paddingTop:"10px"}}>Profissionais altamente capacitados para agilizar o processo<br></br>de compras para aquisição de equipamentos hospitalares.</div>
            </div>

            <div class="container-fluid corrects">
                <div class="row" >
                <div class="col-md-3 text-center">
                    <img style={{position:"absolute",zIndex:999,top:"-30px"}} src="./img/Landing/correct.png"></img>
                </div>
                <div class="col-md-2 text-center">
                    <img style={{position:"absolute",zIndex:999,top:"-30px"}} src="./img/Landing/correct.png"></img>
                </div>
                <div class="col-md-7 text-center pro">
                    <img style={{position:"absolute",zIndex:999,top:"-235px"}} src="./img/Landing/profissional.png"></img>
                </div>
            </div>
            <div class="row">
                <div class="col-3 text-center">
                    <div style={{zIndex:999,marginTop:"60px",marginLeft:"65px"}}>Equipe sua unidade<br></br>de saúde sem<br></br>burocracia</div>
                </div>
                <div class="col-3 text-center">
                    <div style={{zIndex:999,marginTop:"60px",marginLeft:"-50px"}}>100% dentro<br></br>das leis vigentes<br></br>para aquisição</div>
                </div>
            </div>
            </div>
           
            <div class="container-fluid corrects-2">
                <div class="row" style={{position:"relative" ,margin:0, bottom:"30px"}}>
                    <div class="col-6 text-center">
                        <img  src="./img/Landing/correct.png"></img>
                    </div>
                    <div class="col-6 text-center">
                        <img  src="./img/Landing/correct.png"></img>
                    </div>
                </div>
                <div class="row" style={{margin:0}}>
                    <div class="col-6 text-center">
                        <div style={{}}>Equipe sua unidade<br></br>de saúde sem<br></br>burocracia</div>
                    </div>
                    <div class="col-6 text-center">
                        <div style={{}}>100% dentro<br></br>das leis vigentes<br></br>para aquisição</div>
                    </div>
                </div>
            </div>
           
           
            <div class="container-fluid" id="footer">
            <div class="pre-footer">
            <div class="row">
                <div class="col-6">
                    <img style={{position:"absolute",left:"50px",zIndex:999,top:"120px"}} src="./img/Landing/equipamento.png"></img>
                </div>    
                <div class="col-6">
                <div class="h3" style={{fontWeight:700,color:"black",paddingTop:"135px"}}>Orçamento / Mais Informações:</div>
                <div class="p" style={{color:"black",fontSize:18, paddingBottom:"45px"}}>Fale com nossos especialistas e tire suas dúvidas sobre o processo de<br></br>ata hospitalar, bem como preços e demais informações sobre os produtos</div>
                </div>    
            </div>
            </div>
               
            </div>

            <div class="container-fluid pre-footer-2" >
                <div class="container">
    <div class="text-left" style={{color:"black", paddingTop:"60px",paddingBottom:"20px"}}>   
                        <div class="h2">Orçamento / Mais Informações:</div>
                        <div class="p">Fale com nossos especialistas e tire suas dúvidas sobre o processo de<br></br>ata hospitalar, bem como preços e demais informações sobre os produtos</div>
                    </div>
            </div>
                </div>
        

            
            <footer style={{height:"350px", backgroundColor:"#277683"}} >
                <div class="container-fluid pre-footer">
                <div class="row" style={{marginTop:"16px"}}>
                    <div class="col-6 text-center">
                        <div style={{width:"700px", height:"200px"}}></div>
                        <div class="row">
                            <div class="col-2">
                            <a href="https://www.facebook.com/scheffervital" target="_blank">
                                        <img src="./img/Landing/face.png"></img>
                            </a>
                            <a href="https://www.instagram.com/scheffervital" target="_blank">
                                        <img src="./img/Landing/insta.png"></img>
                            </a>
                            </div>
                            <div class="col-5">
                            <div id="numero2" style={{fontWeight:600,fontSize:"21px",color:"white"}}>Fale com um especialista:</div>

                            </div>
                            <div class="col-5" style={{marginLeft:"-70px"}}>
                            <a href="tel:+554137730162" target="_blank">
                              <span id="faleconosco-atas-5" style={{color:"#F0C401",fontWeight:700,fontSize:"21px"}} >(41) 3773-0162</span>
                            </a>
                            </div>

                        </div>
                        
                      
                        <div></div>
                    </div>
                    <div class="col-6">
                        <Form onSubmit={sendEmail}>
                            <div class="row">
                                <div class="col-12 ">
                                <InputMask class="form-control form-atas" maskChar="_" placeholder='Nome' name='nome' onChange={(e) => setName(e.target.value)} value={name} required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                <InputMask class="form-control form-atas" maskChar="_" placeholder='Instituição / Empresa' name='cnpj' onChange={(e) => setCnpj(e.target.value)} value={cnpj} required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 ">
                                <InputMask class="form-control form-atas"  mask="(99)9 9999-9999" maskChar="_" placeholder='Telefone' name="telefone" onChange={(e) => setFone(e.target.value)} value={fone} required/>
                                </div>
                                <div class="col-7 ">
                                <InputMask class="form-control form-atas" type="email" maskChar="_" placeholder='Email' name='email' onChange={(e) => setEmail(e.target.value)} value={email} required/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <Button style={{fontWeight:700, fontSize:"18px", padding:"7px 60px 7px 60px", backgroundColor:"#F0C401",borderRadius:"10px"}}  type="submit" class="btn ">Enviar</Button>
                                </div>
                            </div>
                            
                            
                        </Form>
                    </div>
                </div>
                </div>
               
            <div class="container-fluid pre-footer-2">
                <div class="container text-center" style={{marginTop:"20px"}} id="footer">
                <Form onSubmit={sendEmail} id="form-atas">
                            <div class="row">
                                <div class="col-12 ">
                                <InputMask class="form-control form-atas" maskChar="_" placeholder='Nome' name='nome' onChange={(e) => setName(e.target.value)} value={name} required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                <InputMask class="form-control form-atas" maskChar="_" placeholder='Instituição / Empresa' name='cnpj' onChange={(e) => setCnpj(e.target.value)} value={cnpj} required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 ">
                                <InputMask class="form-control form-atas"  mask="(99)9 9999-9999" maskChar="_" placeholder='Telefone' name="telefone" onChange={(e) => setFone(e.target.value)} value={fone} required/>
                                </div>
                                <div class="col-7 ">
                                <InputMask class="form-control form-atas" type="email" maskChar="_" placeholder='Email' name='email' onChange={(e) => setEmail(e.target.value)} value={email} required/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <Button id="btn-form-atas" style={{fontWeight:700, fontSize:"18px", padding:"7px 60px 7px 60px", backgroundColor:"#F0C401",borderRadius:"10px"}}  type="submit" class="btn ">Enviar</Button>
                                </div>
                            </div>
                        </Form>
                    <div class="container d-flex align-items-center justify-content-center">
                        <div class="linha-footer"></div>
                    </div>
                    <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6">
                    <div class="text-center" style={{color:"white"}}>   
                        <p class="p footer-text">Móveis Hospitalares</p>
                        <p class="p footer-text">Camas Hospitalares</p>
                        <p class="p footer-text">Cadeira de Banho</p>
                        <p class="p footer-text">Cadeira de Rodas</p>
                    </div>

                    </div>
                    <div class="col-3">
                        <div class="text-right" style={{marginTop:"40px"}}>    
                        <a href="https://www.facebook.com/scheffervital" target="_blank">
                            <img src="./img/Landing/face.png"></img>
                        </a>
                        <a href="https://www.instagram.com/scheffervital" target="_blank">
                                    <img src="./img/Landing/insta.png"></img>
                        </a>
                        </div>
                    </div>

                    </div>
                    
                </div>
            </div>

            </footer>

        </>
    )
}