import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom"


var item1 = [1, 33, "Cadeira de Rodas", 12, "10/2023", "img/itens/Cadeira1.png", 3];
var item2 = [2, 410, "Papagaio em Inox", 221, "09/2023", "img/itens/Papagaio1.png", 1];
var item3 = [3, 12, "Cadeira de Rodas Obeso", 10, "08/2023", "img/itens/Cadeira2.png", 3];
var item4 = [4, 51, "Maca Para Transporte", 1, "12/2023", "img/itens/Maca1.png", 2];
var item5 = [4, 63, "Maca Ginecológica", 1, "12/2023", "img/itens/Maca2.png", 2];
var item6 = [5, 3, "Cadeira de Banho", 2, "11/2023", "img/itens/CadeiraBanho1.png", 3];
var item7 = [5, 4, "Cadeira de Rodas", 3, "11/2023", "img/itens/Cadeira3.png", 3];
var item8 = [6, 5, "Cadeira de Rodas", 10, "05/23", "img/itens/Cadeira4.png", 3];
var item9 = [7, 79, "Cadeira de Banho Infantil", 10, "12/2023", "img/itens/CadeiraBanho2.png", 3];
var item10 = [7, 80, "Cadeira de Banho Obeso", 10, "12/2023", "img/itens/CadeiraBanho2.png", 3];
var item11 = [8, 1, "Cadeira de Rodas Obeso", 1, "11/2023", "img/itens/Cadeira5.png", 3];
var item12 = [9, 119, "Suporte de Soro", 17, "12/2023", "img/itens/Suporte1.png", 1];
var item13 = [9, 121, "Poltrona Reclinável", 12, "12/2023", "img/itens/Poltrona1.png", 1];
var item14 = [9, 146, "Cadeira de Rodas", 9, "12/2023", "img/itens/Cadeira6.png", 3];
var item15 = [10, 1, "Escada Dois Degraus", 80, "10/2023", "img/itens/Escada1.png", 0];
var item16 = [10, 16, "Suporte de Soro", 60, "10/2023", "img/itens/Suporte1.png", 0];
var item17 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item18 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item19 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item20 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item21 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item22 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item23 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item24 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item25 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item26 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item27 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item28 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item29 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];
var item30 = [0, 0, "x", 0, "X/2023", "img/itens/X.png", 0];


export function Home() {
    const $ = window.$;
    var alterClass = function() {
    var ww = document.body.clientWidth;
    if (ww < 1080) {
        $('#cat1').removeClass('col');
        $('#cat1').addClass('col-4');
        $('#cat2').removeClass('col');
        $('#cat2').addClass('col-4');
        $('#cat3').removeClass('col');
        $('#cat3').addClass('col-4');
    } else if (ww >= 768) {
        $('#cat1').removeClass('col-4');
        $('#cat1').addClass('col');
        $('#cat2').removeClass('col-4');
        $('#cat2').addClass('col');
        $('#cat3').removeClass('col-4');
        $('#cat3').addClass('col-3');
    };
    };
    $(window).resize(function(){
    alterClass();
    });
    return (
        <>
        <div class="container-fluid fixed-top topo" >
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-md-10">
                            <div class=" text-center logo-vital">
                                <img class="logo" src="img/vital-logo-topo.png"></img>
                            </div>
                        </div>
                        <div id="numero" class="col-8 col-md-2">
                            <button type="button" class="btn btn-light">
                                <a href="tel:+4137730162">
                                <img src="img/icon-atendimento-vital.png" class="ligacao"></img><span id="faleconosco-home-1" class="faleconosco">41 3773-0162</span>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
        </div>
        <div class="container-fluid banner-fluid ata-banner" style={{backgroundImage: 'url(../img/background.png)',}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 banner-texto" style={{marginTop: '120px'}}>
                            <h1 style={{fontSize: '3.7rem'}}>
                                Atas Hospitalares
                            </h1>
                            <h5 style={{fontSize: ''}}>
                                Cadeiras de Rodas, Camas Hospitalares e Mobiliarios Hospitalares.
                            </h5>
                        </div>
                        <div class="col-md-6"></div>
                    </div>
                </div>
        </div>
        <div class="container-fluid banner1" style={{backgroundImage: 'url(../img/teste.png)', marginTop:"50px"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6"  style={{backgroundImage: 'url(../img/idoso.jpg)',border:"2px solid gainsboro",borderRadius:"20px", height:"417px"}}>
                        </div>
                        <div class="col-md-6 banner1-text">
                        <h5>
                                Selecione abaixo o item <br></br>
                                E tenha acesso a atas de Cadeiras de Rodas, Camas Hospitalares e Moveis Hospitalares
                        </h5>
                        </div>
                    </div>
                </div>
        </div>
        <h4 class="h2 text-center ch3">Selecione a ATA</h4>
        <div class="faixa-cinza" style={{marginBottom:"20px"}}></div>
        <div class="container">
        <div id="itens" class="col"> 
            <div class="row"> 
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link id="teste" to='/item/1' state={{numAta:item1[0],numItem:item1[1],nome:item1[2],qnt:item1[3],valid:item1[4],src:item1[5],}}><img class="card-img-top" src={item1[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item1[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item1[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/2' state={{numAta:item2[0],numItem:item2[1],nome:item2[2],qnt:item2[3],valid:item2[4],src:item2[5],}} ><img class="card-img-top" src={item2[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item2[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item2[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/3' state={{numAta:item3[0],numItem:item3[1],nome:item3[2],qnt:item3[3],valid:item3[4],src:item3[5],}} ><img class="card-img-top" src={item3[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item3[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item3[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to={{pathname: '/item/4'}} state={{numAta:item4[0],numItem:item4[1],nome:item4[2],qnt:item4[3],valid:item4[4],src:item4[5],}} ><img class="card-img-top" src={item4[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item4[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item4[3]}</small></p>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/5' state={{numAta:item5[0],numItem:item5[1],nome:item5[2],qnt:item5[3],valid:item5[4],src:item5[5],}}><img class="card-img-top" src={item5[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item5[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item5[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/6' state={{numAta:item6[0],numItem:item6[1],nome:item6[2],qnt:item6[3],valid:item6[4],src:item6[5],}} ><img class="card-img-top" src={item6[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item6[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item6[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/7' state={{numAta:item7[0],numItem:item7[1],nome:item7[2],qnt:item7[3],valid:item7[4],src:item7[5],}} ><img class="card-img-top" src={item7[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item7[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item7[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to={{pathname: '/item/8'}} state={{numAta:item8[0],numItem:item8[1],nome:item8[2],qnt:item8[3],valid:item8[4],src:item8[5],}} ><img class="card-img-top" src={item8[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item8[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item8[3]}</small></p>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/9' state={{numAta:item9[0],numItem:item9[1],nome:item9[2],qnt:item9[3],valid:item9[4],src:item9[5],}}><img class="card-img-top" src={item9[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item9[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item9[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/10' state={{numAta:item10[0],numItem:item10[1],nome:item10[2],qnt:item10[3],valid:item10[4],src:item10[5],}} ><img class="card-img-top" src={item10[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item10[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item10[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/11' state={{numAta:item11[0],numItem:item11[1],nome:item11[2],qnt:item11[3],valid:item11[4],src:item11[5],}} ><img class="card-img-top" src={item11[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item11[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item11[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to={{pathname: '/item/12'}} state={{numAta:item12[0],numItem:item12[1],nome:item12[2],qnt:item12[3],valid:item12[4],src:item12[5],}} ><img class="card-img-top" src={item12[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item12[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item12[3]}</small></p>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/13' state={{numAta:item13[0],numItem:item9[1],nome:item13[2],qnt:item13[3],valid:item13[4],src:item13[5],}}><img class="card-img-top" src={item13[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item13[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item13[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/14' state={{numAta:item14[0],numItem:item14[1],nome:item14[2],qnt:item14[3],valid:item14[4],src:item14[5],}} ><img class="card-img-top" src={item14[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item14[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item14[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div  class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to='/item/15' state={{numAta:item15[0],numItem:item15[1],nome:item15[2],qnt:item15[3],valid:item15[4],src:item15[5],}} ><img class="card-img-top" src={item15[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item15[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item15[3]}</small></p>
                    </div>
                    </div>
                </div>
                <div class="col cards">
                    <div class="card-fixed" style={{borderColor:'white',}}>
                    <Link to={{pathname: '/item/16'}} state={{numAta:item16[0],numItem:item16[1],nome:item16[2],qnt:item16[3],valid:item16[4],src:item16[5],}} ><img class="card-img-top" src={item16[5]} alt="Card image cap"></img></Link>
                    <div class="card-body-fixed">
                        <h5 id="itemTitulo0" class="card-title itemTitulo">{item16[2]}</h5>
                        <div class="faixa-azul" style={{height:'3px', margin: '2px'}}></div>
                        <p id="itemQnt0" class="card-text"><small class="text-muted">Und {item16[3]}</small></p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div> 
        <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 rodape"><img src="img/logo-rodape.png"></img></div>
                            <div class="col-md-2 text-end">
                                <a href="https://www.facebook.com/scheffervital" target="_blank">
                                    <img src="img/face.png"></img>
                                </a>
                                <a href="https://www.instagram.com/scheffervital" target="_blank">
                                    <img src="img/insta.png"></img>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </footer>
        </>
    )
}