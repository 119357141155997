import { Routes, Route, Link } from "react-router-dom"
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Atas } from "./pages/Atas"
import { Home } from "./pages/Home"
import { Item } from "./pages/Item"

function App() {
  return(
    <>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/item/:id" element={ <Item /> } />
        <Route path="/atas" element={ <Atas /> } />
      </Routes>

      <div className="App" id="btn-whatsApp">
        <FloatingWhatsApp
          phoneNumber="+5541999141681"
          accountName="Ana"
          statusMessage="Especialista"
          chatMessage="Olá Como Posso Ajudar?"
          placeholder="Escreva uma mensagem..."
          avatar="./img/logo.png"
          allowEsc
          allowClickAway
          notification
          notificationSound
        />
      </div>
    </>
  ) 
}

export default App 