import { Link, useParams, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';

export function Item(props){
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const location = useLocation().state;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [fone, setFone] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function sendEmail(e) {
    e.preventDefault();
        
        const templateParams = {
            nome: name,
            cnpj: cnpj,
            email: email,
            telefone: fone,

            numAta:location.numAta,
            produto:location.nome,
            numItem:location.numItem,
            qnt:location.qnt,

            data:today.toLocaleDateString(),
        }
        
        emailjs.send('service_2d9161b','template_db58fsn',templateParams,'FBOz0yqrWJ-MgppvE')
        .then((response) => {
            console.log("Email Enviado");
        })
    }
    function EmailModal(send) {
        if (send){
            <>

            </>
        }
    }
    return (
            <>
                    <div class="container-fluid fixed-top topo" >
                        <div class="container">
                            <div class="row">
                                <div class="col-4 col-md-10">
                                    <div class=" text-center logo-vital">
                                        <img class="logo" src="img/vital-logo-topo.png"></img>
                                    </div>
                                </div>
                            <div id="numero" class="col-8 col-md-2">
                                <button type="button" class="btn btn-light">
                                    <a href="tel:+4137730162">
                                    <img src="img/icon-atendimento-vital.png" class="ligacao"></img><span id="faleconosco-item-1" class="faleconosco">41 3773-0162</span>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                        <img class="imgProduto" src={location.src} alt=""></img>
                        </div>
                        <div style={{marginTop: '110px',}} class="col">
                        <h1  class="itemTitulo">{location.nome}</h1>
                        <ul class="list-unstyled itemTxt" style={{marginLeft: '5px',}}>
                            <li style={{paddingBottom:'5px',}}>Quantidade Minima: <strong>A Consultar</strong></li>
                            <li style={{paddingBottom:'5px',}}>Quantidade Maxíma: <strong>{location.qnt}</strong></li>
                            <li style={{paddingBottom:'5px',}}>Validade da Ata: <strong>{location.valid}</strong></li>
                        </ul>
                        <Button variant="success" class="btn btn-success btn-lg ata" onClick={handleShow}> Acessar ATA</Button>
                        </div>
                    </div>
                </div>
                        <Modal show={show} onHide={handleClose} size="lg">
                            <div id="fundoModal">
                                <Modal.Header style={{paddingTop:"0",}}>
                                <img src="img/vital-logo-topo.png" alt=""></img>
                                </Modal.Header>
                                <Modal.Body>
                                    <h5 style={{color:'black'}}>Insira os dados para acessar a ATA</h5>
                                        <div id="corpoformulario"  onSubmit={sendEmail}>
                                            <Form class="form needs-validation" onSubmit={handleClose} id ="form-itens"> 
                                                <div class="row g-3" id="formModal"  >
                                                <div class="col-12">
                                                    <InputMask class="form-control" maskChar="_" placeholder='Nome' name='nome' onChange={(e) => setName(e.target.value)} value={name} required />
                                                </div>
                                                <div class="col-md-6">
                                                    <InputMask class="form-control"  mask="(99)9 9999-9999" maskChar="_" placeholder='Telefone' name="telefone" onChange={(e) => setFone(e.target.value)} value={fone} required/>
                                                </div>
                                                <div class="col-md-6">
                                                    <InputMask class="form-control" type="email" maskChar="_" placeholder='Email' name='email' onChange={(e) => setEmail(e.target.value)} value={email} required/>
                                                </div>
                                                <div class="col-12">
                                                    <InputMask class="form-control"  mask="99.999.999/9999-99" maskChar="_" placeholder='CNPJ (Opcional)' name='cnpj' onChange={(e) => setCnpj(e.target.value)} value={cnpj} />
                                                </div>
                                                <Form.Group>
                                                    <Modal.Footer>
                                                        <Button variant="success" type="submit" class="btn btn-success" id="btn-form-item" >Acessar ATA</Button>
                                                    </Modal.Footer>
                                                </Form.Group>
                                                </div>
                                            </Form>
                                        </div>
                                </Modal.Body>
                            </div>
                        </Modal>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 rodape"><img src="img/logo-rodape.png"></img></div>
                            <div class="col-md-2 text-end">
                                <a href="https://www.facebook.com/scheffervital" target="_blank">
                                    <img src="img/face.png"></img>
                                </a>
                                <a href="https://www.instagram.com/scheffervital" target="_blank">
                                    <img src="img/insta.png"></img>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </footer>
            </>
        )
}

export default Item